import * as msal from '@azure/msal-browser';
import { authority, clientId } from '../api/constants';

const msalConfig = {
  auth: {
    clientId: clientId, // This is the ONLY mandatory field that you need to supply.
    authority: authority, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: '/login', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
export const loginRequest = {
  scopes: ['User.Read'],
};

export const apiRequest = {
  scopes: ['https://api-dev.roshalimaging.org/api/.default'],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };
