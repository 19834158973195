import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useRoom from '../../VideoProvider/useRoom/useRoom';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  buttonConnect: {
    color: 'white',
    background: 'green',
    '&:hover': {
      background: '#016001',
    },
  },
  buttonDisconnect: {
    color: 'white',
    background: 'red',
    '&:hover': {
      background: '#600101',
    },
  },
}));

interface ConnectToggleButtonProps {
  name: string;
  roomName: string;
  className?: string;
}

export default function ConnectToggleButton({ name, roomName, className }: ConnectToggleButtonProps) {
  const classes = useStyles();
  const { getToken } = useAppState();
  const { connect: videoConnect, isConnecting, room } = useVideoContext();

  // @ts-ignore
  const isConnected = window.twilioRoom;

  const handleClick = () => {
    // @ts-ignore
    if (window.twilioRoom) {
      console.log('ConnectToggleButton.handleJoin: disconnecting');
      room!.disconnect();
      // @ts-ignore
      window.twilioRoom = null;
      return;
    }

    console.log('ConnectToggleButton.handleJoin: name=', name, 'roomName=', roomName);

    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
    });
  };

  return (
    <Button
      className={`${isConnected ? classes.buttonDisconnect : classes.buttonConnect} ${className}`}
      onClick={() => handleClick()}
      disabled={isConnecting}
    >
      {// @ts-ignore
      window.twilioRoom ? 'Disconnect' : 'Connect'}
    </Button>
  );
}
