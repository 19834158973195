import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useLocation, useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);
  const query = useQuery();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [order, setOrder] = useState<any>();

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {}, []);

  useEffect(() => {
    fetch('/mock/Roshal-dispatch-summary.json')
      .then(data => data.json())
      .then(data => data.dispatches)
      .then(dispatches => {
        const user_identity = query.get('user_identity') || user?.displayName || '';

        console.log('user_identity: %o', user_identity);

        if (user_identity) {
          setName(user_identity);
        }

        const order = dispatches?.find((dispatch: any) => dispatch.gid === URLRoomName) || dispatches?.[0];

        console.log('dispatches: %o', dispatches);
        console.log('order: %o', order);

        setOrder(order);

        console.log('*** URLRoomName: ' + URLRoomName);
        if (URLRoomName) {
          setRoomName(URLRoomName);
          if (user_identity && URLRoomName) {
            setStep(Steps.deviceSelectionStep);
          }
        }
      });
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          order={order}
          setName={setName}
          setRoomName={setRoomName}
          setOrder={setOrder}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} order={order} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
