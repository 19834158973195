import React, { useEffect, useState } from 'react';
import { FormControl, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import AvatarIcon from '../../../icons/AvatarIcon';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import LocalVideoPreview from '../../PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';
import { useAppState } from '../../../state';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import SignOutButton from '../../Buttons/SignOutButton/SignOutButton';
import ConnectToggleButton from '../../Buttons/ConnectToggleButton/ConnectToggleButton';
import { useLocation, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: '100%',
    //paddingTop: `${(9 / 16) * 100}%`,
    // background: 'black',
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  identityContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.18em 0.3em',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
  button: {
    position: 'absolute',
    bottom: '10px',
    left: '30%',
    margin: '0 auto',
    width: '40%',
    zIndex: 1,
  },
  menuPaper: {
    maxHeight: 400,
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function MyDeviceSelectorView({ identity }: { identity: string }) {
  const classes = useStyles();
  const query = useQuery();

  const { URLRoomName } = useParams<{ URLRoomName?: string }>();

  const { isFetching, user } = useAppState();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, room } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { videoInputDevices } = useDevices();
  const { getAudioAndVideoTracks, localTracks } = useVideoContext();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [order, setOrder] = useState<any>();

  useEffect(() => {
    getAudioAndVideoTracks().catch(error => {
      console.log('Error acquiring local media:');
      console.dir(error);
    });

    if (isAudioEnabled) {
      toggleAudioEnabled();
    }
  }, [getAudioAndVideoTracks]);

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  useEffect(() => {
    fetch('/mock/Roshal-dispatch-summary.json')
      .then(data => data.json())
      .then(data => data.dispatches)
      .then(dispatches => {
        const user_identity = query.get('user_identity') || user?.displayName || '';

        console.log('user_identity: %o', user_identity);

        if (user_identity) {
          setName(user_identity);
        }

        const order = dispatches?.find((dispatch: any) => dispatch.gid === URLRoomName) || dispatches?.[0];

        console.log('dispatches: %o', dispatches);
        console.log('order: %o', order);

        setOrder(order);

        console.log('*** URLRoomName: ' + URLRoomName);
        if (URLRoomName) {
          setRoomName(URLRoomName);
        }
      });
  }, [user, URLRoomName]);

  return (
    <div style={{ border: '1px solid black', background: 'white', width: '100%', height: '100%' }}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <FormControl fullWidth>
            <Select
              onChange={e => replaceTrack(e.target.value as string)}
              value={localVideoInputDeviceId || ''}
              variant="outlined"
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {videoInputDevices.map(device => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalVideoPreview identity={localVideoTrack?.kind || ''} />
        </div>
      </div>

      <ConnectToggleButton
        roomName={URLRoomName || ''}
        name={query.get('user_identity') || ''}
        className={classes.button}
      />
    </div>
  );
}
