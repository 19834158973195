import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import RoshalLogo from '../../icons/RoshalLogo';
import SignOutButton from '../Buttons/SignOutButton/SignOutButton';
import PersonIcon from '../../icons/PersonIcon';
import MyDevicesButton from '../Buttons/MyDevicesButton/MyDevicesButton';
import DocumentButton from '../Buttons/DocumentButton/DocumentButton';
import InviteButton from '../Buttons/InviteButton/InviteButton';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import InviteDialog from '../InviteDialog/InviteDialog';
import DocumentWindow from '../DocumentWindow/DocumentWindow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.grey[200],
      top: 0,
      left: 0,
      right: 0,
      height: `30px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileHeaderHeight}px`,
        padding: 0,
      },
    },
    container: {
      backgroundColor: theme.palette.background.default,
      top: 30,
      left: 0,
      right: 0,
      height: `${theme.headerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    logo: {
      top: 0,
      left: 0,
      padding: '0 0.25em',
      height: '24px',
      width: 'auto',
      fill: theme.palette.primary.main,
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    personIcon: {
      height: '24px',
      width: '24px',
      marginLeft: '0.5em',
      fill: theme.palette.grey[500],
    },
    signoutButton: {
      height: '24px',
      width: 'auto',
      marginLeft: '0.5em',
      marginRight: '0.5em',
      button: {
        background: theme.danger,
        color: theme.danger,
        border: `none`,
        margin: '0 2em',
        '&:hover': {
          background: theme.palette.grey[400],
        },
      },
    },
    inviteButton: {
      marginRight: '10px',
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const {
    isSharingScreen,
    toggleScreenShare,
    setIsMyDevicesSelectionOpen,
    setIsBackgroundSelectionOpen,
  } = useVideoContext();
  const { setIsChatWindowOpen } = useChatContext();
  const { isDocumentWindowOpen, setIsDocumentWindowOpen } = useVideoContext();
  const [inviteOpen, setInviteOpen] = useState(false);
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const [order, setOrder] = useState<any>();

  function updateOrder(roomName: String) {
    try {
      fetch('/mock/Roshal-dispatch-summary.json')
        .then(data => data.json())
        .then(data => {
          const dispatch = data.dispatches.filter((row: any) => row.gid === roomName)[0];
          setOrder(dispatch.order);
          return dispatch.order;
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (room) {
      if (!order) {
        updateOrder(room.name);
      }
    }
  }, [room, order]);

  return (
    <>
      <div className={classes.title}>
        <Grid container justifyContent="space-around" alignItems="center">
          {/* <Hidden smDown> */}
          <Grid style={{ flex: 1 }}>
            <RoshalLogo className={classes.logo} />
          </Grid>
          {/* </Hidden> */}
          {/* <Grid item>
            <Grid container justifyContent="center">
              <Typography variant="body1">{order?.pid || room?.name}</Typography>
              <ExpandMoreIcon />
            </Grid>
          </Grid> */}
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-end">
              <Hidden smDown>
                <Typography variant="body1">{room?.localParticipant?.identity || 'Participant'}</Typography>
                <PersonIcon className={classes.personIcon} />
              </Hidden>
              <SignOutButton className={classes.signoutButton} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <header className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">
                <MyDevicesButton
                  onClick={() => {
                    setIsMyDevicesSelectionOpen(true);
                    setIsBackgroundSelectionOpen(false);
                    setIsChatWindowOpen(false);
                  }}
                />
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
              <Hidden smDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <DocumentButton className={classes.inviteButton} onClick={() => setIsDocumentWindowOpen(true)} />
                <InviteButton className={classes.inviteButton} onClick={() => setInviteOpen(true)} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </header>
      <InviteDialog
        open={inviteOpen}
        onClose={() => {
          setInviteOpen(false);
        }}
      />
      <DocumentWindow
        open={isDocumentWindowOpen}
        onClose={() => {
          setIsDocumentWindowOpen(false);
        }}
      />
    </>
  );
}
