import React, { useEffect } from 'react';
import MyDevicesSelectionHeader from './MyDevicesSelectionHeader/MyDevicesSelectionHeader';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import LocalVideoPreview from '../PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';
import { LocalVideoTrack } from 'twilio-video/tsdef/LocalVideoTrack';
import VideoTrack from '../VideoTrack/VideoTrack';
import MyDeviceSelectorView from './MyDeviceSelectorView/MyDeviceSelectorView';
import AddVideoSessionButton from './AddVideoSessionButton/AddVideoSessionButton';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    display: 'flex',
    width: theme.leftDrawerWidth,
    height: `calc(100% - ${theme.footerHeight}px)`,
  },
  thumbnailContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    overflowY: 'auto',
  },
  innerContainer: {
    width: '100%',
    height: '215px',
    marginTop: '5px',
  },
  addVideoStreamButton: {
    margin: '0 auto',
    display: 'block',
  },
}));

function MyDevicesSelectionDialog() {
  function createRandomString(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  //const base_url = 'http://localhost:3000';
  const base_url = 'https://remote.roshalhealth.com';

  const classes = useStyles();
  const { isMyDevicesSelectionOpen, setIsMyDevicesSelectionOpen } = useVideoContext();
  const { localTracks, room, extraStreamUrls } = useVideoContext();
  const [refresh, setRefresh] = React.useState(false);
  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  useEffect(() => {}, [refresh]);

  // function openNewSession() {
  //   window.open(
  //     `${base_url}/room/${room_name}?user_identity=${user_identity}&no_audio=true`,
  //     '_blank'
  //   );
  // }

  function addNewStream() {
    const room_name = room?.name || 'unknown';
    const user_identity = (room?.localParticipant?.identity || 'unknown') + '_' + createRandomString(4);

    const newStreamUrl = `${base_url}/room_device/${room_name}?user_identity=${user_identity}&no_audio=true`;
    extraStreamUrls.push(newStreamUrl);

    setRefresh(!refresh);
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isMyDevicesSelectionOpen}
      transitionDuration={0}
      classes={{
        paper: classes.drawer,
      }}
    >
      <MyDevicesSelectionHeader onClose={() => setIsMyDevicesSelectionOpen(false)} />
      <div className={classes.thumbnailContainer}>
        <div className={classes.innerContainer}>
          <VideoTrack track={videoTrack} isLocal />
        </div>

        {extraStreamUrls.map(extraStreamUrl => {
          return (
            <iframe
              src={extraStreamUrl}
              className={classes.innerContainer}
              scrolling="no"
              style={{ backgroundColor: 'green', overflow: 'hidden' }}
            ></iframe>
          );
        })}

        <div className={classes.innerContainer}>
          <AddVideoSessionButton className={classes.addVideoStreamButton} onClick={addNewStream} />
        </div>
      </div>
    </Drawer>
  );
}

export default MyDevicesSelectionDialog;
