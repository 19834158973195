import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import OrderGrid from '../../OrderGrid/OrderGrid';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  order: any;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  setOrder: (order: any) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
  name,
  roomName,
  order,
  setName,
  setRoomName,
  setOrder,
  handleSubmit,
}: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const [nameEntered, setNameEntered] = useState<boolean>(false);
  const [orderSelected, setOrderSelected] = useState<boolean>(false);
  const [dispatches, setDispatches] = useState<any>();

  useEffect(() => {
    fetch('/mock/Roshal-dispatch-summary.json')
      .then(data => data.json())
      .then(data => setDispatches(data.dispatches));
  }, []);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleContinue = () => {
    setNameEntered(true);
  };

  const handleOrderRowClick = (row: any) => {
    setOrder(row.data);
    setRoomName(row.data.gid);
    setOrderSelected(true);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  const getUserNameView = (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" className={classes.gutterBottom}>
          Sign In
        </Typography>
        <Typography variant="body1">To start, please enter your name.</Typography>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                type="text"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type={roomName ? 'submit' : 'button'}
            color="primary"
            disabled={!name}
            className={classes.continueButton}
            onClick={() => {
              if (!roomName) {
                handleContinue();
              } else {
                setOrder(dispatches[0].order);
                setOrderSelected(true);
              }
            }}
          >
            {roomName ? 'Configure Device' : 'Select Room'}
          </Button>
        </Grid>
      </form>
    </>
  );

  const getOrderView = (
    <>
      {/* <Typography variant="h5" className={classes.gutterBottom}>
        Current Orders
      </Typography> */}
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <OrderGrid onOrderRowClick={handleOrderRowClick} />
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!orderSelected}
            className={classes.continueButton}
          >
            Connect to Order
          </Button>
        </Grid>

        <div className={classes.inputContainer}>
          <TextField
            hidden={true}
            id="input-user-name"
            variant="outlined"
            fullWidth
            size="small"
            defaultValue={name}
            value={name}
          />

          <TextField
            hidden={false}
            autoCapitalize="false"
            id="input-room-name"
            variant="outlined"
            fullWidth
            size="small"
            defaultValue={roomName}
            value={roomName}
          />
        </div>
      </form>
    </>
  );

  if (!nameEntered) {
    return getUserNameView;
  } else {
    return getOrderView;
  }
}
