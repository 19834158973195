import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';

const columns = [
  { field: 'id', headerName: 'ORDER ID' },
  { field: 'ordered_at', headerName: 'ORDERED AT' },
  { field: 'status', headerName: 'STATUS' },
  { field: 'location', headerName: 'LOCATION' },
  { field: 'mrn', headerName: 'MRN' },
  { field: 'provider_name', headerName: 'PROVIDER' },
  { field: 'provider_phone', headerName: 'PROVIDER PHONE' },
];

interface OrderGridProps {
  onOrderRowClick?: (row: any) => void;
}

const OrderGrid = ({ onOrderRowClick }: OrderGridProps) => {
  const [tableData, setTableData] = useState([]);

  function massageData(data: any) {
    return data.map((dispatch: any) => {
      const order = dispatch.order;
      return {
        id: order.pid,
        pid: order.pid,
        gid: order.gid,
        ordered_at: order.ordered_at,
        status: order.state.description,
        location: order.location.name,
        mrn: order.mrn,
        provider_name: dispatch.provider.name,
        provider_phone: dispatch.provider.phone,
      };
    });
  }

  useEffect(() => {
    fetch('/mock/Roshal-dispatch-summary.json')
      .then(data => data.json())
      .then(data => setTableData(massageData(data.dispatches)));
  }, []);

  return (
    <div style={{ height: 225, width: '100%' }}>
      <DataGrid rows={tableData} columns={columns} hideFooter={true} onRowClick={onOrderRowClick} pageSize={12} />
    </div>
  );
};

export default OrderGrid;
