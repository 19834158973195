import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);
interface AddVideoSessionButtonProps {
  onClick?: () => void;
  className?: string;
}

export default function AddVideoSessionButton({ onClick, className }: AddVideoSessionButtonProps) {
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={clsx(classes.button, className)} data-cy-disconnect>
      Add Video Session
    </Button>
  );
}
