import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactPhoneInput, { CountryData } from 'react-phone-input-material-ui';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  phoneContainer: {
    minHeight: '120px',
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
}));

const endpoint = `${process.env.REACT_APP_BASE_ENDPOINT || '/api'}/invite`;

function invitePhoneNumber(phone: string, room_name: string) {
  console.log('invitePhoneNumber: phone: ' + phone + ', room_name: ' + room_name);
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      phone: `+${phone}`,
      room: room_name,
    }),
  });
}

export default function DeviceSelectionDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<Error | null>(null);

  const { room } = useVideoContext();

  useEffect(() => {
    const phoneNumberUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

    if (phoneNumber.length === 0) {
      setPhoneNumberError(new Error('Invalid phone number'));
      return;
    }

    try {
      const phoneNumberObj = phoneNumberUtil.parse(phoneNumber, 'US');
      if (!phoneNumberUtil.isValidNumber(phoneNumberObj)) {
        setPhoneNumberError(new Error('Invalid phone number'));
      } else {
        setPhoneNumberError(null);
      }
    } catch (e) {
      setPhoneNumberError(new Error(`${e}`));
    }
  }, [phoneNumber]);

  function onSendInvitation() {
    if (!room) {
      return;
    }

    invitePhoneNumber(phoneNumber, room.name);

    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>Send an Invitation</DialogTitle>
      <Divider />
      <DialogContent className={classes.container}>
        <Typography variant="h5" className={classes.gutterBottom}>
          Invite a new participant to this Roshal Health remote telehealth session.
        </Typography>
        <Grid container justifyContent="space-between">
          <div className={classes.phoneContainer}>
            <ReactPhoneInput
              // variant="outlined" TODO: figure out how to style this
              value={phoneNumber}
              country={'us'}
              onChange={(value: string, data: {} | CountryData, e: ChangeEvent<HTMLInputElement>) =>
                setPhoneNumber(value)
              }
              inputClass={classes.field}
              dropdownClass={classes.countryList}
              component={TextField}
            />
            {/* <TextField
                            id="input-phone-number"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                            type="text"
                            variant="outlined"
                            size="small"
                        /> */}
            <div>
              {phoneNumberError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {phoneNumberError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="secondary" variant="contained" className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={phoneNumberError != null}
          onClick={onSendInvitation}
        >
          Send Invitation
        </Button>
      </DialogActions>
    </Dialog>
  );
}
